import { NO_PRICE_TEXT } from "@/src/const";
import { VendorType, VenueType } from "@/src/type";
import { CatalogItemLocation, PriceDurationBE, PriceDurationFE, SearchType } from "@/src/type/blaceV2";
import {
  Search,
  SearchItem,
  SearchRoom,
} from "@/src/type/blaceV2/search/SearchType";
import { PriceHelper } from "@/src/util";
import * as CMSLogic from "./CMSLogic";

export function getAddressForDisplay(
  venue?: Search,
  fullAddress?: boolean
): string | undefined {
  if (!venue) {
    return;
  }

  const location = venue.locations?.length ? venue.locations[0] : undefined;

  if (location) {
    if (fullAddress && typeof location.formattedAddress === "string") {
      return location.formattedAddress
        .replace(", United States", "")
        .replace(", USA", "");
    }

    return `${location.streetNumber ?? ""} ${
      location.street ? `${location.street}, ` : ""
    }${location.city}`.trim();
  }
}

export function getVenueAddressForDisplay(
  locations?: CatalogItemLocation[]
): string {
  if (!locations?.length) {
    return "";
  }
  const location = locations[0];
  const address = location?.formattedAddress
    ?.replace(", United States", "")
    ?.replace(", USA", "");
  return address || "";
}

export function getVenueContact(venue: SearchType.SearchItem): string {
  // TODO: migrate to V2 data structure

  // V1 data structure
  const contactName = (venue?.data as VenueType.VenueItem)?.primary_contact
    ?.first_name;
  return contactName || "";
}

export function formatVenuePricing(
  venue?: SearchItem,
  prePriceText?: string
): string {
  // TODO: migrate to V2 data structure

  // V1 data structure
  if (!venue || !venue?.price || !venue?.price?.displayPrice) {
    return NO_PRICE_TEXT;
  }
  if (typeof venue?.price?.minimumStartingPrice !== "number") {
    return NO_PRICE_TEXT;
  }

  const formattedPrice = PriceHelper.formatToCurrency(
    venue?.price?.minimumStartingPrice
  );
  const priceUnit = venue?.price?.priceUnit ?? PriceDurationFE.PerDay;

  return `${prePriceText ? `${prePriceText} ` : ""}${formattedPrice} / ${priceUnit}`
    .replace(".00", "");
}

export function getPricingDetails(venue: SearchItem): string {
  // TODO: migrate to V2 data structure

  // V1 data structure
  const details = venue.price?.details;

  return details || "";
}

export function getVenueRooms(
  venue: SearchItem
): (SearchRoom | VenueType.Room)[] {
  const rooms = hasVenueV2Rooms(venue)
    ? venue?.rooms?.filter((roomItem) => roomItem.isPublished)
    : (venue?.data as VenueType.VenueItem)?.rooms;

  return rooms ?? [];
}

export function hasVenueV2Rooms(venue: SearchItem): boolean {
  return typeof venue?.rooms?.length !== "undefined";
}

export function getExclusiveVendors(
  venue: SearchItem
): VendorType.Vendor[] | undefined {
  // TODO: migrate to V2 data structure

  // V1 data structure
  return (venue?.data as VenueType.VenueItem)?.exclusive_vendors;
}

export function getAttributesByType(
  attributeType: SearchType.SearchAttributeType,
  attributes?: SearchType.SearchAttribute[]
): undefined | Record<string, SearchType.SearchAttribute> {
  if (!attributes || !Array.isArray(attributes)) {
    return undefined;
  }

  const parsed: Record<string, SearchType.SearchAttribute> = {};
  let hasData: boolean = false;
  for (const attribute of attributes) {
    if (
      attribute?.attributeType === attributeType &&
      typeof attribute?.label === "string"
    ) {
      hasData = true;
      parsed[attribute.label] = attribute;
    }
  }

  if (!hasData) {
    return undefined;
  }

  return parsed;
}

export function formatPricingDuration(
  pricingDuration?: string
): string {
  if (!pricingDuration) {
    return PriceDurationFE.PerDay;
  }

  switch (pricingDuration) {
    case PriceDurationBE.PerHalfDay:
      return PriceDurationFE.PerHalfDay;
    case PriceDurationBE.PerHour:
      return PriceDurationFE.PerHour;
    case PriceDurationBE.PerPerson:
      return PriceDurationFE.PerPerson;
    case PriceDurationBE.PerDay:
    default:
      return PriceDurationFE.PerDay;
  }
}

export function formatVenueRoomPricing(
  room: SearchType.SearchRoom
): string | undefined {
  if (!room?.showPricing || !room?.pricingValueInCents) {
    return NO_PRICE_TEXT;
  }

  return `${PriceHelper.formatToCurrency(PriceHelper.centsToDollars(room.pricingValueInCents), true, true)} / ${
    formatPricingDuration(room.pricingDuration)
  }`;
}

export function formatVenueRoomPricingV1(
  room: VenueType.Room
): string | undefined {
  if (!room?.short_pricing_details_label || !room?.price) {
    return NO_PRICE_TEXT;
  }

  return `${PriceHelper.formatToCurrency(room.price)} / ${
    room.short_pricing_details_label ?? PriceDurationFE.PerDay
  }`.replace(".00", "");
}

/**
 * Transform the V1 categories into a string list for use in search of similar venues
 *
 * @param {VenueType.VenueCategory[]} categories - a list of categories from the v1 database
 * @returns {string[]} - just a list of the categories by name
 */
export const toCategoriesArray = (
  categories: VenueType.VenueCategory[]
): string[] => {
  return (categories ?? []).length === 0 ? [] : categories.map((c) => c.name);
};

export function getVenueCity(
  venue: SearchItem
): string | undefined {
  // the V1 data structure
  const fallBackCity = CMSLogic.citySelection((venue.data as VenueType.VenueItem)?.city_id || "");

  // the V2 data structure
  return venue.locations?.[0]?.city || fallBackCity;
}
